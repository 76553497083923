import './App.css';

function App() {
  return (
    <div className="kusari">
      <main>
        <section className="py-5 text-center container main-header">
          <div className="kusari-bg"></div>
          <header className="kusari-header" id="tc-header">
            <div className="uk-container uk-container-center">
              <div className="logo">
                <a href="/">
                { false && <img src="images/logos/logo.png" alt="logo"/> }
                鎖 Kusari
                </a>
              </div>
              <div className="socials">
              <a href="https://twitter.com/KusariVentures" rel="noreferrer" target="_blank" data-uk-tooltip="{pos:'bottom'}"><i className="fa fa-twitter"></i></a>
              </div>
            </div>
          </header>
          <div className="header-container">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light">Kusari Ventures</h1>
              <p className="lead text-muted">
                Kusari Ventures is a advisory and investment firm that supports blockchain projects.
                We help startups and established companies in all phases of a blockchain product: Raising funds, Partneships, Growth, Tech Advisory and more.
              </p>
              <p>
              Onwards and upwards.
              </p>
            </div>
          </div>
          <div className="down-arrow">
            <a href="#services" data-uk-smooth-scroll="{offset: 0}"><i className="fa fa-arrow-down"></i></a>
          </div>
        </section>

        <div id="services" className="album py-5 kusari-container">

          <div className="container">
            <h1 class="title1 uk-margin-top-remove mb30">What We Do</h1>
            <div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 g-3">

              <div className="col">
                <div className="card shadow-sm">
                  <div className="services-item-wrap service-item">
                    <div className="services-item">
                      <div className="services-icon">
                        <i className="fa fa-pie-chart"></i>
                      </div>
                      <div className="services-desc">
                        <h2 className="services-title">Advisory</h2>
                        <p>
                          We incubate great blockchain ideas, we help on every phase of the project, even if it is just an idea.
                          <br/> From the technical implementation of the concept to growing the user baser after the project is launched.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-sm">
                  <div className="services-item-wrap service-item">
                    <div className="services-item">
                      <div className="services-icon">
                        <i className="fa fa-btc"></i>
                      </div>
                      <div className="services-desc">
                        <h2 className="services-title">Fundraising</h2>
                        <p>
                        Take your vision to the next level. Great ideas need funding, it's not just about making it a reality but also reach a high audience. 
                        We invest and help to reach your goals. Your success is our success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card shadow-sm">
                  <div className="services-item-wrap service-item">
                    <div className="services-item">
                      <div className="services-icon">
                        <i className="fa fa-line-chart"></i>
                      </div>
                      <div className="services-desc">
                        <h2 className="services-title">Partnerships</h2>
                        <p>Build a better infrastructure with us. We can connect your project to our best fitting partners, so you can also create and grow your strategic alliances. We work with you.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        { false && <div className="py-5 kusari-container">
          <div className="container">
            <h1 class="title1 uk-margin-top-remove mb30">Portfolio</h1>
            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 g-3"></div>
          </div>
        </div> }

        <div className="py-5 kusari-container">
          <div className="container">
            <h1 class="title1 uk-margin-top-remove mb30">Contact Us</h1>

            <form action="https://submit-form.com/76rNw3j1">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 mb30">
                <div className="col">
                  <div className="input-container"><input className="kusari-input" type="text" name="name" placeholder="Your Name" required=""></input></div>
                  <div className="input-container"><input className="kusari-input" type="text" name="email" placeholder="Your Email" required=""></input></div>
                </div>
                <div className="col">
                  <div className="input-container"><textarea className="kusari-input" name="message" placeholder="Tell use a bit about your project" required=""></textarea></div>
                </div>
              </div>
              <div className="row row-cols-1 input-container">
                <div className="col"><input type="submit" className="kusari-button" value="Send"/></div>
              </div>
            </form>
          </div>
        </div>

      </main>

      <footer className="text-muted py-5 kusari-container border-top">
        <div className="container">
          <p className="float-end mb-1">
            <a href="#">Back to top</a>
          </p>
          <p className="float-end mb-1 mr-5">
          <a href="https://twitter.com/KusariVentures" rel="noreferrer" target="_blank" data-uk-tooltip="{pos:'bottom'}"><i className="fa fa-twitter"></i></a>
          </p>
          <p className="mb-1">Copyright 2022 Kusari Ventures ÖU. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
